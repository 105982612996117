<template lang="pug">
b-container.py-3
  b-row(align-v='stretch')
    b-col.mb-3.scroll(md='5', lg='4')
      b-card.mb-0.navbar_container
        h3.font-weight-bold(@click='showNav') Productos
        b-nav.text-font(vertical, :class='{ navbar: mobile, navbar_collapse: !mobile }')
          div(v-for='(item, index) in catalog')
            b-nav-item.font-weight-bold(v-b-toggle='\'collapse_\' + index')
              plus-icon.mr-2.mb-1.icon-md
              span.uppercase {{ item.brand }}
            b-collapse.pl-4(:id='"collapse_" + index', v-if='category(item.brand)')
              b-nav(vertical)
                div(v-for='(subItem, subIndex) in category(item.brand)')
                  b-nav-item.font-weight-bold(v-b-toggle='\'collapse_inner_\' + subIndex') {{ subItem.category }}
                  b-collapse.pl-3(:id='"collapse_inner_" + subIndex', v-if='subcategory(item.brand, subItem.category)')
                    b-nav(vertical)
                      b-nav-item(v-for='(sub2Item, sub2Index) in subcategory(item.brand, subItem.category)', :key='sub2Item.subcategory', @click='selectOption(sub2Item.index)') {{ sub2Item.subcategory }}
    b-col.mb-3.scroll(md='7', lg='8')
      b-card.mb-0.navbar_container
        b-row
          b-col.font-weight-bold.mb-3 {{ title() }}
        b-row(align-v='stretch')
          b-col.pointer.product-box(cols='6', md='4', v-for='product in filteredProducts', :key='product.index', @click='$router.push({ name: "Product", params: { product_id: product.index } })')
            b-img.image(:src='`${server}/images/${product.image}.png`', fluid, style='max-height: 200px', center, v-if='product.image')
            b-img.image(src='~@/assets/images/no_image.png', fluid, style='max-height: 200px', center, v-else)
            .text-center.mb-5
              span.product-name {{ product.name }}
</template>

<script>
import { mapState } from 'vuex'

export default {
  data() {
    return {
      option: 0,
      filteredProducts: null,
      mobile: false,
      server: process.env.VUE_APP_URL_SERVER,
    }
  },
  computed: {
    ...mapState({
      brands: (state) => state.brands,
      products: (state) => state.products,
    }),
    catalog() {
      let brand = this.brands
        .map((value) => ({
          brand: value.brand,
        }))
        .filter((value, index, self) => index === self.findIndex((element) => element.brand === value.brand))

      return brand
    },
  },
  methods: {
    selectOption(option) {
      this.option = option
      this.filterProducts()
    },
    category(brand) {
      let category = this.brands
        .map((value) => ({
          brand: value.brand,
          category: value.category,
        }))
        .filter((value, index, self) => index === self.findIndex((element) => element.brand === brand && element.category === value.category))

      return category
    },
    subcategory(brand, category) {
      let subcategory = this.brands
        .map((value, index) => ({
          index,
          brand: value.brand,
          category: value.category,
          subcategory: value.subcategory,
        }))
        .filter((value) => value.brand === brand && value.category === category)

      return subcategory
    },
    filterProducts() {
      let filters = this.brands[this.option]
      this.filteredProducts = this.products.filter((value) => value.brand.toUpperCase() === filters.brand && value.category.toUpperCase() === filters.category && value.subcategory.toUpperCase() === filters.subcategory)
    },
    title() {
      return Object.values(this.brands[this.option]).join(' / ')
    },
    showNav() {
      this.mobile = !this.mobile
    },
  },
  mounted() {
    this.filterProducts()
  },
}
</script>

<style lang="scss">
.text-font{
  font-size: 12px;
}

.navbar_container {
  height: fit-content !important;
}

.navbar {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: left;
}

.navbar_collapse {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: left;

  @media (max-width: 767px) {
    display: none;
  }
}

.image {
  width: 200px;
  height: 200px;
}

.scroll {
  height: calc(100vh - 150px);
  overflow-y: auto;
}

.nav-item .nav-link {
  color: black !important;
}

.catalog-nav-item {
  font-weight: 700 !important;
}

.product-box {
  &:hover {
    span.product-name {
      text-decoration: underline;
    }
  }
}
</style>